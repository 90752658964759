.module-card-details-component {
  border-radius: 8px;
  border: 1px solid #BEEDF6;
  background: #F5FDFF;
  padding: 20px;

  .title-text-configuration {
    color: var(--Text-Blue, #202937);
    font-size: 16px;
    font-weight: 600;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.1px;
  }

  .module-card-details {
    width: 33.33%;
    margin-right: 10px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #BEEDF6;
    background: #FFF;
  }

  .module-card-text-header {
    color: var(--Text-Blue, #202937);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }

  .module-card-text-details {
    color: var(--facility-vars-grays-davys-grey, #4E535F);
    /* Font/14px - 400 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    word-break: break-all
  }
}