@import "../variables";

.t-tabs-wrapper {
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;

  .t-tabs {
    //border-bottom: 1px solid #dfdfdf;
    //max-height: 20px;

    .t-tab {
      border-left: 1px solid #E4E7EA;
      background: #FFFFFF;
      text-transform: unset !important;
      font-weight: $font-weight-normal;
      //border: 2px solid red;

      &:first-child {
        border-left: none;
      }
    }

    .t-tab.Mui-selected {
      font-weight: $font-weight-bold;
    }

    &.primary {
      .t-tab.Mui-selected {
        background: $side-menu-active-link-bg-color !important;
        color:#202937;
      }
    }
  }

  .t-tab-panel {
    padding: 10px 0 !important;
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}
