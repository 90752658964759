.facility-hcp-rate-component {
  height: 100%;
  display: flex;
  flex-direction: column;

  .input-component .input-field {
    padding: 5px 12px;
    height: 26px;
  }


  .facility-hcp-rate-header {
    flex: 0 0 auto;
  }

  .rate-component {
    height: calc(100% - 80px);
    display: grid;
    width: 100%;
    grid-template-columns: auto auto; /* Create two equal columns */
    gap: 10px; /* Add a gap between items */
    overflow: auto;
    border-radius: 12px;
    border: 1px solid #ECECEC;
    padding: 10px;
    //border: 2px solid red;

    .list-item {
      padding: 10px 20px;
      height: 48px;
      align-items: center;
      min-width: 440px;
      max-width: 440px;
    }


  }

  .button-wrapper {
    //flex: 1;
    margin-top: 15px;
  }

}

.rate-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rate-normal-from {
  //height: 100%;
  display: flex;
  flex-direction: column;
}


.hover-component {


  .hover-component-header {
    padding: 20px 6px 10px 20px;
  }

  .details-holder {
    background-color: #FAFAFA;
    padding: 10px 15px;
  }
}

.file-wrapper-after-upload {
  border-radius: 8px;
  border: 1px solid var(--facility-vars-primary-pacific-cyan, #0DB9D8);
  background: var(--New-light-cyan-button, #E7F8FB);
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 10px;

  .file-name {
    margin-top: 2px;
  }

  .remove-icon {
    background-color: black;
    border-radius: 50%;
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


