.pending-shift-view-details {
  .MuiTabs-root {
    width: 35rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 12px;
      font-weight: 700;
    }
  }
  .pending-header {
    //background: linear-gradient(180deg, #FFF 23.96%, #FFF4EA 100%);
  }

}