@import "src/assets/styles/common";

.select-dropdown-component {
  position: relative;
  margin-bottom: $font-control-margin-bottom;
  white-space: nowrap;
  cursor: pointer;

  .select-dropdown__control {
    border-color: $input-border-color;
  }
  &.fullWidth {
    width: 100%;

    .input-field {
      width: 100%;
    }
  }

  &.disabled {
    .select-dropdown__control {
    }
  }

  &.has-error {
    .select-dropdown__control {
      color: $select-error-color;
      border-color: $select-error-border-color;
    }
  }

  &.small {
    .select-dropdown__control {
      line-height: $select-line-height - 10px - 8px;
    }

    .select-dropdown__single-value {
      font-size: 14px;
    }
  }

  .select-dropdown__menu {
    z-index: 99999999 !important;
  }


  .select-dropdown__indicator-separator {
    display: none;
  }

  &.search {
    .select-dropdown__control {
      line-height: $select-line-height - 8px;
    }
  }

  .select-dropdown__control__is-focused {
    //background-color: transparent;
  }

  .select-dropdown__control {
    //background-color: #FCFCFC;
    //border: $select-border;
    //line-height: $select-line-height;
    //font-size: $select-font-size;
    //font-weight: $select-font-weight;
    //outline: none !important;
    //box-shadow: none !important;
    background-color: #FFF;
    max-height: 36px;
    min-height: 36px;
    .select-dropdown__placeholder {
      color: $input-placeholder-color;
      font-size: 14px;
      font-weight: 400;
      opacity: 1 !important;
    }

    .select-dropdown__control__is-focused {
      border: $select-border;
      background-color: #F3FEFF;
      box-shadow: none;
      outline: none;
    }

    .select-dropdown__value-container {
      padding: 2px 10px !important;
    }

    .select-dropdown__indicator {
      padding: 8px 20px 8px 10px;
      color: #2d2d2d;

      &.select-dropdown__clear-indicator {
        padding: 0 !important;
      }
    }

    .select-dropdown__option {
      white-space: normal !important;
    }
  }
}

.select-dropdown__menu-portal {
  z-index: 9999 !important;

  .select-dropdown__menu-list {
    :first-child {
      background: transparent !important;
      color: inherit !important;

      &:hover {
       // cursor: default !important;
        background: #F3FEFF !important;
      }

      &.select-dropdown__option--is-selected {
        background: #F3FEFF !important;
        // color: #fff !important;
      }
    }
  }
}

.custom-select:focus {
  outline-color: red !important; /* Change this color to your desired clicked border color */
}
