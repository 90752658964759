@import "src/assets/styles/variables";
@import "src/assets/styles/helpers";

.attachment-upload-wrapper {
max-width: 300px;
  .attachment-upload-label {
    letter-spacing: 0.5px;
    color: #000000;
    opacity: 0.5;
    font-weight: $font-weight-bold;
  }

  .attachment-upload-list {
    margin: 5px 0;

    .attachment-upload-item {
      margin: 0 30px 30px 0;
      display: flex;
      flex-wrap: wrap;

      .attachment-upload-item-title {
        margin: 10px 0;
        opacity: 0.5;
        font-size: 14px;
      }

      .attachment-upload-item-uploaded-file-wrapper, .attachment-upload-item-upload-file-wrapper {
        border: 1px solid #C7CFD2;
        border-radius: 5px;
        height: 100px;
        width: 150px;
        cursor: pointer;
        position: relative;

        .attachment-upload-item-uploaded-file, .attachment-upload-item-upload-file {
          @include display-center();
          height: 100%;
          flex: 1;

          .attachment-upload-item-uploaded-icon, .attachment-upload-item-upload-icon {

            height: 40px;
            width: auto;
            position: relative;
            top: -5px;

            svg {
              opacity: 0.8;
              height: 100%;
              width: 100%;
            }
          }

        }

        .attachment-upload-item-uploaded-close {
          position: absolute;
          top: -12px;
          right: -12px;
          cursor: pointer;

        }
      }

    }
  }
}

.attachment-preview-dialog {

  .attachment-preview-dialog-header {
    text-align: center;

    .attachment-preview-dialog-title {

    }
  }

  .attachment-preview-dialog-body {
  }
  .uploaded-attachment-file-name{
    margin: 15px 0;
  }

  .attachment-preview-dialog-footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}