// App.scss
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 40px;

    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      border-radius: 50%;
      transition: .4s;
    }
  }

  input[type="checkbox"]:checked + .slider {
    background-color: #1FAE6A;

    &::before {
      transform: translateX(16px);
    }
  }

  &.round {
    .slider {
      border-radius: 34px;

      &::before {
        border-radius: 50%;
      }
    }
  }
}
