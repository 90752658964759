@import "src/assets/styles/common";

.not-found-screen {
  width: 100vw;
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;

  .not-found-wrapper {
    @include display-center();
    flex-direction: column;

    .not-found-go-back {
      padding: 10px 20px;
      border: 1px solid #4a4a4a;
      text-align: center;

      &:hover {
        font-weight: 500;
      }

    }

  }
}
