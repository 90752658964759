@import "src/assets/styles/common";

.radio-component {
  clear: both;
  height: $radio-button-height;
  margin-right: 20px;

  .label-component {
    margin: 0 !important;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .radio-container {
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    margin-bottom: 15px;

    &.disable {
      cursor: not-allowed;

      .radio-checkmark {
        background-color: colorDarkness($radio-button-background-color, 10) !important;
      }

      &:hover {
        .radio-checkmark {
          background-color: colorDarkness($radio-button-background-color, 10) !important;
        }
      }
    }

  }

  .radio-container .radio-field {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .radio-checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    border: 1.5px solid $input-border-color;
    background-color: $input-background-color;
  }

  //.radio-container:hover .radio-field ~ .radio-checkmark {
  //  //background-color: colorDarkness($primary-color, 2);
  //}
  //
  //.radio-container .radio-field:checked ~ .radio-checkmark {
  //  background-color: var(--primary-color);
  //}

  &.checked {
    .radio-container .radio-field:checked ~ .radio-checkmark {
      border: 4px solid $radio-button-base-color;
    }
  }

  .radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio-container .radio-field:checked ~ .radio-checkmark:after {
    display: block;
  }

  //.radio-container .radio-checkmark:after {
  //  left: 3px;
  //  top: 3px;
  //  width: 10px;
  //  height: 10px;
  //  border-color: $radio-button-base-color;
  //  background: $radio-button-base-color;
  //  border-radius: 50%;
  //}

  &.selected {
    .radio-checkmark {
      border: 4px solid $radio-button-base-color !important;
    }
  }

}
