// AntTableComponent.scss
@import "src/assets/styles/variables";
.responsive-table-container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-color: white;
  border-radius: 0.7rem;

  .ant-table-wrapper{
    border-radius: 10px;
    box-shadow: none;
    border-bottom: 1px solid #F0F0F0;
    border-left: 0.7px solid #F0F0F0;
    border-right: 0.7px solid #F0F0F0;
  }

  .ant-table-header{
    font-family: $font-family;
  }


  .ant-table-body {
    min-height: 70vh;
    max-height: 70vh !important;
    border-bottom: 0.7px solid #DFDFDF;
    font-family: $font-family;
  }

  .ant-table-expanded-row-fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    max-height: 70vh !important;
  }

  .ant-pagination {
    //margin: 1rem 1rem !important;
    padding:.5rem 1rem !important;
    display: flex;
   .ant-pagination-item{
     padding: 8px;
     margin-right:8px ;
     display: flex;
     align-items: center;
     justify-content: center;
     font-family: $font-family;
   }

    .ant-pagination-total-text{
      flex: 1;
    }
    .ant-pagination-item-active{
      background-color: #F1FDFF;
      border:1px solid $side-menu-active-link-bg-color;
      a{
        color: #000000;
      }
    }

    .ant-pagination-prev, .ant-pagination-next{
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-pagination-options{
      padding-left:2rem;
    }

    .ant-pagination-options .ant-select-selector{
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -5px;
    }
    .ant-select-selector .ant-select-selection-item{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family;
    }
  }
}

.ant-table-thead .ant-table-cell{
  padding-left: 20px !important;
}

.ant-table-row .ant-table-cell {
  padding-left: 20px !important;
}

/* Customize the checkbox checked background */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4CAF50 !important; /* Replace with your desired color */
  border-color: #4CAF50 !important; /* Adjust the border color */
}

/* Customize the indeterminate state background (partial selection) */
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #FFC107 !important; /* Indeterminate checkbox color */
}

/* Customize the table header checkbox specifically */
thead .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4CAF50 !important; /* Checked background color for header checkbox */
}

thead .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #FFC107 !important; /* Indeterminate background color for header checkbox */
}

/* Customize the hover effect */
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #4CAF50 !important; /* Border color on hover */
}