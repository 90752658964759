.video-player-component {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  .video-player-controls {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #fff;
    padding: 10px 20px;
  }

  video {
    // display in center of screen
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
