@import "src/assets/styles/common";

.status-card-component {
  min-height: auto;
  max-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .status-card-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8B8E90;
    white-space: pre-line;
  }
}
