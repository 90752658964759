.shift-for-text {
  color: #5E6368;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 114.286%; /* 13.714px */
}

.text-patient-name {
  font-size: 14px;
  //font-style: italic;
  font-weight: 600;
  line-height: 114.286%;
}
