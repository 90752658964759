.cancelled-shift-view-details {
  .MuiTabs-root {
    width: 35rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 12px;
      font-weight: 700;
    }
  }
  .approved-header {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #FFF 23.96%, #FFEFEF 100%);
    // padding: 10px;
  }


  .shift-details__timesheet {
    display: flex;
    justify-content: right;
  }
}

.document-list-wrapper {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
}

.open-shift-details-accordian {
  .MuiTypography-root {
    color: #192938;
    font-weight: 600;
    margin: 0;
  }

  .MuiAccordionSummary-root {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background: #F1FBFD
  }

  .MuiAccordionDetails-root {
    background: #F1FBFD
  }
}

.cancelled-card {
  padding-left: 12px;
  padding-top: 12px;
  margin-bottom: 14px;
  border-radius: 6px;
  border: 1px solid #BEEDF6;
  background: #F5FDFF;
}
