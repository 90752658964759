.search-component {
  .medium{
    height: 36px;
  }
  .search-close-icon {
    background-color:#ffffff;
    padding-left:10px;
    :hover {
      cursor: pointer;
      //height: 36px;
    }
  }
}
