@import "animations";
@import "variables";
@import 'react-toastify/dist/ReactToastify.css';
@import "fonts";

@import "helpers";
@import "mixins";
@import "responsive-mixins";
@import "responsive-display";
@import "normalize";

// components
@import "components/button";
@import "components/loader";
@import "components/form-controls";
@import "components/scrollbar";
@import "components/table";
@import "components/attachments";
@import "components/modal";
@import "components/card";
@import "components/drawer";
@import "components/tabs";
@import "components/list-screen";
@import "components/grid";
@import "components/drop-down-menu";
@import "components/details-screen";
@import "components/header";

.app-version {
  position: fixed;
  bottom: 5px;
  right: 5px;
  color: var(--vars-colors-table-text-gray, #9DA4AC);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

//.auth-layout {
//  width: 100vw;
//  height: 100vh;
//  background: none;
//
//  .mask-icon-container {
//    position: absolute;
//    top: 0;
//    right: 0;
//  }
//
//  .branding-component {
//    width: 50vw;
//    height: 100vh;
//    position: absolute;
//    left: 0;
//    right: unset;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    background: url("./../../assets/images/banner-image.png") no-repeat left;
//    background-size: 50vw 100vh;
//    .logo-wrapper {
//      height: 48px;
//      width: 456px;
//      position: absolute;
//      top: 30px;
//      left: 15px;
//
//      img {
//        width: 100%;
//        height: 100%;
//      }
//
//
//      @include lg() {
//        top: 49px;
//        left: 36px;
//      }
//    }
//
//    //@include md() {
//    //  background: url("./../../assets/images/banner-image.png") no-repeat left;
//    //  background-size: 50vw 100vh;
//    //}
//    //
//    //@include lg() {
//    //  background: url("./../../assets/images/banner-image.png") no-repeat left;
//    //  background-size: 50vw 100vh;
//    //}
//
//  }
//
//  .auth-wrapper {
//    //width: 50vw;
//    height: 100vh;
//    background: #fff;
//    position: absolute;
//    left: unset;
//    right: 150px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    // padding: 0 60px;
//
//    //@include md() {
//    //  width: 480px;
//    //}
//
//
//    .auth-form {
//      @include display-center();
//      flex-direction: column;
//
//      .auth-form-title {
//        font-size: 24px;
//        font-weight: $font-weight-bold;
//        margin: 10px 0 30px 0;
//        text-align: left;
//      }
//
//      .auth-form-sub-title {
//        //text-align: center;
//        margin: 30px 0;
//        color: $black;
//        opacity: 0.5;
//      }
//    }
//
//  }
//}

.app-layout {
  display: flex;

  .side-bar-holder {
    width: #{$side-bar-holder-width}px;
    min-width: #{$side-bar-holder-width}px;
    height: 100vh;
    background: url("../icons/side-menu/side_menu_background_wave.svg") bottom / 100% auto no-repeat, linear-gradient(180deg, #D3F8FB 0%, #EEF9FC 100%);
    position: relative;
    //padding: 16px;
    padding: 16px 11px 16px 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #EAECF0;

    .logo-holder {
      height: #{$branding-holder-height}px;
    }

    .side-menu-holder {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 10px;
    }

    &.default-view {
      transition: 0.2s width;

      .logo-sm {
        display: none;
      }
    }

    &.expanded-view-view {
      width: 213px;
      transition: 0s width;
    }

    &.compact-view {

      width: 48px;
      min-width: 48px;
      transition: 0.2s width;

      .logo-default {
        //display: none;
      }

      .menu-item {
        @include display-center();
        margin: 2px;
        padding: 8px;

        .menu-item-icon {
          margin: 2px;
        }
      }

      .menu-item-title {
        display: none;
      }

      .branding-component {
        width: 20px;
        height: auto;
      }
    }

    .side-menu-toggle-icon {
      position: absolute;
      bottom: 120px;
      right: -18px;
      background: #fff;
      padding: 5px;
      border-radius: 50%;
      display: flex;
      border: 1px solid #E1E1E1;
      box-shadow: 0 4px 4px rgb(0 83 79 / 23%);
      cursor: pointer;
      color: $side-bar-toggle-icon-color;
    }
  }

  .header-and-page-container {
    overflow: hidden;
    flex: 1;

    .header-holder {
      height: $header-height + px;
      width: 100%;
      background: $header-bg-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 30px;
      border-bottom: $header-border-bottom;
    }

    .page-content-holder {
      //background: $page-content-bg-color;
      //padding: 20px 16px 14px;
      //background: url("../icons/page_content_wave_left.svg") left top no-repeat,
      //url("../icons/page_content_wave_right.svg") right top no-repeat,
      //$page-content-bg-color;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .sidebar-view-switch-icon {
    position: absolute;
    top: calc(100% - 160px);
    left: calc(100% - (100% - 42px));
    right: unset;
    z-index: 2;
    cursor: pointer;
  }

  .sidebar-view-switch-icon-right {
    position: absolute;
    top: calc(100% - 127px);
    left: calc(100% - (100% - 190px));
    right: unset;
    z-index: 2;
    cursor: pointer;
  }
}


.add-layout {
  background: #EBF1FD;
  height: 100vh;

  .add-layout-header {
    height: 47px;
    background: #ffffff;
    border-bottom: 1px solid #DFDFDF;
    padding: 0 20px;

    .add-layout-branding {
      position: absolute;
      left: 32px;
    }

    .add-layout-title {
      font-size: 18px;
      line-height: 24px;
      font-weight: $font-weight-bold;
    }
  }

  .add-layout-content-holder {
    background: #FAFAFA;
    //height: calc(100vh - 47px);
    width: 100vw;
    overflow: auto;

    .t-form-screen {
      height: 100%;

      .t-form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .t-form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 66px;
        border-bottom: 1px solid #DFDFDF;
        padding: 12px 24px;

        .t-form-header-right {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }

      .t-form-content {
        padding: 24px;
        overflow: auto;
        flex: 1;
      }

    }

  }
}

.icon-button {
  cursor: pointer;
}

.display-center {
  @include display-center();
}

.clear-fix {
  clear: both;
}

.horizontal-line {
  border-bottom: 1px solid black;
  opacity: 0.2;
  margin: 15px 0 30px 0;
}

.vertical-line {
  border-left: 1px solid black;
  opacity: 0.2;
  margin: 15px 0 30px 0;
}

.height-100 {
  height: 100%;
}

.t-drawer-details {
  display: flex;
  height: 100vh;

  .MuiDrawer-paper {
    min-width: 500px;
    border-radius: 100px;

    .drawer-container {
      padding: 0 !important;

      .drawer-header {
        padding: 16px 24px;
        margin: 0 !important;
      }


      .drawer-body {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 55px);

        .drawer-content {
          padding: 16px 24px;
          flex: 1 1;
          overflow: auto;
        }

        .drawer-actions {
          padding: 20px 24px;
          display: flex;
          column-gap: 20px;
          justify-content: center;
        }
      }
    }
  }

}

.t-drawer-details-with-action-header {
  display: flex;
  height: 100vh;

  .MuiDrawer-paper {
    min-width: 500px;

    .drawer-container {
      padding: 0 !important;

      .drawer-header {
        padding: 16px 24px;
        margin: 0 !important;
      }


      .drawer-body {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 75px);

        .drawer-content {
          padding: 16px 24px;
          flex: 1 1;
          overflow: auto;
        }

        .drawer-actions {
          padding: 20px 24px;
          display: flex;
          column-gap: 20px;
          justify-content: center;
        }
      }
    }
  }
}

.cancel-poll-modal {
  .modal-content-wrapper {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-body {
      width: 400px;

      .cancel-poll-heading {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .cancel-poll-sub-heading {
        text-align: center;
        color: #5F5F61;
        line-height: 20px;
        margin-bottom: 18px;
      }

      .input-component {
        width: 350px;
      }
    }
  }
}

.font-weight-bold {
  font-weight: bold;
}

.text-primary {
  color: $primary-color;
}

.text-error {
  color: $error-color;
}

.h-v-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.add-screen {
  height: 100%;

  .t-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .add-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    border-bottom: 1px solid #DFDFDF;
    padding: 12px 24px 12px 0;
  }

  .add-component-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .scroll-content {
      overflow: auto;
      flex: 1;
    }
  }

}

.ellipses-for-table-data {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipses-for-details-data {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-apex-chart-bar-graph-tooltip {
  background: #EBF1FD;
  border-radius: 4px;
  padding: 2.5px 6px;

  .custom-apex-chart-bar-graph-tooltip-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0478F0;
  }
}

.apexcharts-legend {
  flex-direction: column !important;
  overflow: hidden !important;

  .apexcharts-legend-series {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;

    .apexcharts-legend-marker {
      height: 15px !important;
      width: 22px !important;
      border-radius: 2px !important;
    }
  }
}

.apexcharts-tooltip {
  transform: translateY(12px);
}

.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-flex-align {
  display: flex;
  justify-content: left;
}

.center-right-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-form-wrapper-box {
  border-radius: 10px;
  border: 1px solid var(--facility-vars-grays-border-gray, #E4E7EA);
  background: var(--facility-vars-primary-white, #FFF);
  box-shadow: 0px 4px 25px 6px rgba(40, 47, 65, 0.04);
  margin-left: 10px;
  margin-right: 10px;
  height: 87vh;
  overflow: auto;
}

.button-height {
  height: 32px;
}

.button-font-text {
  font-size: 14px;
  font-weight: 700;
}

.form-header-text {
  font-family: $font-family, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.half-width {
  width: 50%;
}

.greater-half-width {
  width: 55%;
}

.back-text-color {
  color: #202937;
}

.home-text {
  color: var(--facility-vars-grays-caded-gray, #9EA2A8);
  font-family: $font-family, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.underline-text {
  text-decoration: underline;
  color: #5E6368;
}

.underline-text-view {
  color: var(--Vars-Colors-Table-Text-Blue, #202937);
  font-size: 14px;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
}

.step-text {
  color: var(--facility-vars-primary-pacific-cyan, #0DB9D8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.961px; /* 164.005% */
}

.step-tracker {
  //width: '100%',
  //height: '6px',
  //backgroundColor: '#e7f8fb',
  //borderRadius: "10px"

  width: 100%;
  height: 6px;
  background-color: #e7f8fb;
  border-radius: 10px;
}

.header-add-agency-form {
  font-weight: 700;
  font-size: 18px;
}

.card-background-color {
  background-color: #FAFAFA;
}

.add-button-text {
  color: var(--facility-vars-primary-pacific-cyan, #0DB9D8);
  font-size: 12px;
  font-weight: 600;
}

.header-text-font {
  font-size: 14px;
  font-weight: 600;
  color: #192938;
}

.mrg-bottom-set-zero {
  margin-bottom: 0 !important;
}

.alert-text-display {
  color: var(--facility-vars-primary-pacific-cyan, #0DB9D8);
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
}

.information-text {
  margin-top: 20px;
  width: 33rem;
  text-align: center;
  color: #9EA2A8;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.rate-information {
  font-size: 14px;
  font-weight: 500;
  color: #9EA2A8;
}

.rate-information-header {
  font-size: 16px;
  font-weight: 500;
  color: #192938;
}

.list-header-text-property {
  color: var(--facility-vars-grays-davys-grey, #4E535F);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1px;
}


.header-color {
  color: var(--facility-vars-primary-gunmetal, #192938);
}

.flexing {
  display: flex;
  align-items: center;
}

.ellipses-for-calender-card-data {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipses-for-calender-day-month-data {
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.center-the-element-content {
  justify-content: center;
  align-items: center;
}

.contract-action-text {
  color: var(--facility-vars-primary-pacific-cyan, #0DB9D8);
  font-size: 14px;
  font-weight: 400;
}

.tel-input-holder {
  .PhoneInputCountry {
    border: 1px solid #E7E9ED;
    padding: 10px 6px;
    border-radius: 8px;
  }

  .PhoneInputInput {
    outline: none !important;
    border-radius: 8px;
    padding: 8.68px 14px;
    border: 1px solid #E4E7EA;
    background: #FFF;

    &::placeholder {
      color: var(--facility-vars-grays-silver-2, #C6CACF);
      /* Font/14px - 600 */
      font-size: 14px;
      font-weight: 400;
    }

    &:active,
    &:focus {
      padding: 8.68px 14px;
      border: 1px solid #192938;
    }

    font: inherit;
    color: currentColor;
    width: 100%;
    height: 1.189em;
    margin: 0;
    display: block;
    //padding: 6px 0 7px;
    min-width: 0;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: none !important;
    margin: 0px 3px;
  }
}

.MuiList-root {
  display: flex;
  flex-direction: column;
  //.MuiListItemText-root{
  //  padding: 10px;
  //}
  .MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 5px 20px;
  }
}

.requirement-open-status {
  color: #0DB9D8;
  background-color: #d1f4fa;
  border: 1px solid rgba(222, 105, 68, 0.00);
}

.requirement-partially_open-status {
  color: #03353f;
  background-color: #22d3f3;
  border: 1px solid rgba(222, 105, 68, 0.00);
}

.requirement-filled-status {
  color: #053711;
  background-color: #c5fab1;
  border: 1px solid rgba(222, 105, 68, 0.00);
}

.requirement-cancelled-status {
  background: #FFE2E9;
  color: #DE1C4E;
  border: 1px solid rgba(222, 105, 68, 0.00);
}

.requirement-unfilled-status {
  color: #DE6944;
  border: 1px solid rgba(222, 105, 68, 0.00);
  background: var(--facility-vars-status-orange-old-race, #FFF4E5);
}

.requirement-status {
  padding: 2px 20px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 100px;
}

.mrg-top-37 {
  margin-top: 37px;
}

.table-footer-selected-value {
  color: var(--facility-vars-grays-silver-2, #C6C6C6);
  font-size: 14px;
  font-weight: 400;
}

.filter-display-holder {
  display: flex;
  width: 20px;
  height: 21px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #0DB9D8;
  color: white;
}

.flex-right {
  display: flex;
  justify-content: right;
}

.default-alignment {
  justify-content: center;
}

.expanded-alignment {
  padding: 7px;
}

.MuiDrawer-paper {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.drawer-close-button {
  display: flex;
  width: 34px;
  height: 34px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DB5656;
  background: #FFF4F4;
  cursor: pointer;
  color: #DB5656;
}

.edit-drawer-header {
  //background: linear-gradient(180deg, #E0FAFF 23.96%, #FFF 100%);
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawer-edit-save-button {
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  //background: linear-gradient(180deg, #FFF 23.96%, #E0FAFF 100%);
}

.form-top-header-text {
  color: var(--facility-vars-primary-gunmetal, #192938);
  /* Font/14px - 600 */
  font-family: $font-family, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-details-header {
  color: var(--Slate-gray, #797F87);
  font-size: 16px;
  font-weight: 600;
}

.details-card-holder-component {
  border-radius: 8px;
  border: 1px solid #BEEDF6;
  background: #F5FDFF;
}

.form-minor-text {
  color: #5E6368;
  font-size: 14px;
  font-weight: 400;
}

.disabled-state-text-font {
  color: #9CA5B1;

  /* Font/14px - 600 */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}


.pending-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 92px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid rgba(222, 105, 68, 0.24);
  background: #FFF4E5;
  color: #DE6944;
  font-size: 12px;
  font-weight: 400;
}

.confirmed-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 92px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  background: #F2F2F2;
  color: #727272;
  font-size: 12px;
  font-weight: 400;
}

.completed-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 92px;
  padding: 5px 10px;
  background: #E6FFF2;
  border-radius: 4px;
  border: 1px solid #A8ECD3;
  color: #45AE88;
  font-size: 12px;
  font-weight: 400;
}

.canceled-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 92px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #FFCACA;
  background: #FFEFEF;
  color: #FF7575;
  font-size: 12px;
  font-weight: 400;
}

.card-header-nw {
  background: #f5fdff;
  border: 1px solid #beedf6;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #212b36;
  font-weight: 700;
  text-transform: uppercase
}

.edit-drawer {
  .edit-contract-wrapper {
    padding-top: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


  }
}

//.horizontal-line-wrapper {
//  margin-top: 21px;
//  margin-bottom: 18px;
//}

.file-preview-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 11px 12px;
  border-radius: 5px;
  border: 1px solid #E7E7E7;
}

.delete-button {
  color: #EA6F6F;;
}

.button-component {
  &.save-btn {
    color: #FFF;
    background: #192938;
    margin: auto;
  }
}

.internal-view-switch {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px
}

.width-100 {
  width: 100%;
}

.dialog-large-width {
  min-width: 900px;
}

.MuiOutlinedInput-root {
  background: #FFFFFF;
  border-radius: 8px !important;

}

.file-upload-supported-text {
  color: #889199;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.noc-type {
  color: #108CD2;
}

.am-type {
  color: #FF7575;
}

.pm-type {
  color: #10C5D2;
}

.MuiSelect-root {
  &.MuiSelect-select {
    &:focus {
      background-color: white !important;
    }
  }
}

//.MuiOutlinedInput-root.Mui-focused {
//  &.MuiOutlinedInput-notchedOutline {
//    border-color: #0DB9D8 !important;
//  }
//}

.MuiOutlinedInput-root {
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #182A39 !important;
      border-width: 1px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #E7E7E7 !important;
    border-width: 1px !important;
  }

  //border: 1px solid green !important;
}

.full-height {
  height: 85vh;
}

.MuiAutocomplete-input {
  padding: 7px 4px 7px 6px !important;
}

//.MuiSelect-root {
//  .MuiSelect-select:focus {
//    background-color: crimson;
//  }•MuilutlinedInput-root.Mui-focused-MuilutlinedInput-

.stepper-active-container {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background: #25C6E6;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
}

.stepper-disabled-container {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background: var(--Text-Light-Disabled, #919EAB);
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
}

.Mui-active {
  color: #0db9d8 !important;
}

.Mui-completed {
  color: #1FAE6A !important;
}

.drawer-close-button-holder {
  border-radius: 8px;
  border: 1px solid #FF7575;
  background: #FFF4F4;
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  color: #FF7575;
}


.material-select-box-placeholder-custom {
  margin-top: 10px;
  margin-left: 15px;
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: #C6CACF;
  z-index: 1;
}

.shift-requirement-divider-line {
  width: 100%;
  height: 1px;
  background: #BEEDF6;
}

.date-picker-margin {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.d-flex-space-between {
  justify-content: space-between;
}


.hcp-details-header-text-drawer {
  color: #212B36;
  font-size: 14px;
  font-weight: 600;
}

.differential-hcp-rate {
  color: #FF7575;
  font-size: 14px;
  font-weight: 600;
}

.gender-text {
  color: var(--Vars-Colors-Greys-Slate-gray, #797F87);
  font-size: 14px;
  font-weight: 600;
}


.drawer-tab-wrapper {

}

.returning-text {
  color: #FF7575;
  font-size: 14px;
  font-weight: 400;
}

.profile-image-wrapper {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.shift-details-field-text {
  color: var(--Vars-Colors-Greys-Dim-Grey, #5E6368);
  font-size: 14px;
  font-weight: 500;
}

.shift-details-card-hcp {
  // border: 1px solid #E7E7E7;
  background: #FCFCFC;
}

.no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.document-text {
  color: var(--Vars-Primary-Vars-Grey-grey-dark, #9EA2A8);
  font-size: 14px;
  font-weight: 400; /* 142.857% */
}

.document-box {
  max-height: 313px;
  overflow: auto;
}

.hcp-document-list-wrapper {
  padding: 12px 8px;
  display: flex;
  align-items: center;
  //border: 1px solid var(--gray-200, #EAECF0);
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  margin-bottom: 15px;
}

.table-wrapper-box {
  margin-top: 4px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.non-header-container {
  padding: 25px;
}

.MuiAutocomplete-input::placeholder {
  color: #C6CACF !important;
  font-size: 14px;
  font-weight: 400 !important; // Placeholder font size
  opacity: 1 !important;
}

.rmdp-container {
  width: 100%;
}

.select-box-width {
  max-width: 220px;
  min-width: 220px;
}

.MuiDialog-paperWidthXl {
  border-radius: 12px !important;
}

.open-requirement-shift-for-chip {
  display: flex;
  padding: 4px 14px;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid #BEEDF6;
  background: #F5FDFF;
}

.open-details-agency-selection-wrapper {
  border-radius: 8px;
  border: 1px solid #BEEDF6;
  background: #FFF;
}

.open-details-agency-text {
  color: var(--Facility-Vars-Primary-Gunmetal, #192938);
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.shifts-created-for-text {
  color: #5E6368;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.icon-container-button {
  width: 0;
  height: 0;
  border: 0;
  border-radius: 100%;
  background-color: #fff;
}

.custom-input {
  cursor: pointer;
  font-size: 14px;
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 36px;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.custom-input:focus {
  outline: none;
}

.custom-input::placeholder {
  color: #C6CACF;
  font-size: 14px;
  font-weight: 400 !important; /* Replace with your desired color */
}

.details-screen {
  .details-screen-title-with-image-wrapper {
    background-color: #F5FDFF;
    border-radius: 8px;
    margin: 10px;
    border: 1px solid #BEEDF6;
  }

  .details-screen-title {
    font-size: 16px;
    font-weight: 600;
  }

  .list-screen {
    .t-table-wrapper .t-table:not(.t-table-wrapper .t-table.auto-height) {
      //height: auto;
      min-height: auto;
    }

    .t-table-wrapper .t-table .table-no-data-wrapper {
      min-height: 0 !important;
      height: auto !important;
    }

    .facility-table-list {
      border: 1px solid #DFDFDF;
      border-radius: 10px;
    }
  }
}


//.MuiFormControl-root {
//  //margin: 0 !important;
//
//  .MuiInputBase-root {
//    outline: none !important;
//    border-radius: 8px !important;
//    border: none !important;
//
//    .MuiListItemText-root {
//      overflow: hidden;
//      white-space: nowrap;
//      text-overflow: ellipsis
//    }
//
//    //  background: #FAFAFA;
//
//    &:active,
//    &:focus {
//      padding: 11px 14px;
//      border: 1px solid #212B36;
//    }
//  }
//}

.MuiInputBase-root {
  max-height: 36px;
  // border: 1px solid #E7E7E7 !important;
  .MuiInputBase-input {
    padding-left: 10px;

    .MuiListItemText-root {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis
    }
  }
}


.height-hcp-details-wrapper {
  min-height: 50px;
}

.count-box {
  display: flex;
  padding: 3px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #212B36;
  color: #fff;
  margin-left: 10px;
}

.shift-timings-wrapper {
  max-height: 75vh;
  overflow: auto;
}

.shift-title-text {
  color: var(--Facility-Vars-Grays-Davys-grey, #4E535F);
  font-size: 14px;
  font-weight: 300;
  text-decoration-line: underline;
}

.scheduler-ellipses-for-week-shift-text {
  max-width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scheduler-ellipses-for-hcp-name-text {
  min-width: 100px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mrg-7 {
  margin-top: 3px;
}

.view-map{
  background-color: #D1F4FA;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  text-decoration: underline;
  color: #0AB9D8;
  font-size: 14px;
  font-weight: 600;
  margin:10px 0px;
  border: 1px solid #0db9d8;
  cursor:pointer;
}


.facility-admin-add-wrapper{
  border: 1px solid #0DB9D8;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  color: #0AB9D8;
  cursor: pointer;
  font-weight: 500;
  background-color: #D1F4FA;
}
.facility-admin-table-wrapper{
  .ant-table-body{
    max-height: 40vh !important;
    min-height: 40vh !important;
  }
  margin-bottom: 20px;
}