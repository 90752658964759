.assign-hcp-component {
  .hcp-assign-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  .form-container{
    height: 82vh;
    overflow: auto;
  }
  .form-wrapper{
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
  }
  .input-component .input-field {
    padding: 5px 12px;
    height: 26px;
  }
  .input-component{
    margin-bottom: 5px;
  }
}