.shift-filter-drawer-container{
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;

  .shift-filter-container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .shift-filter-button-wrapper{
    position: absolute;
    width: 100%;
    background-color: #FFFFFF;
    padding: 0 20px;
    bottom: 0;
    right: 0;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
.scheduler-filter-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}