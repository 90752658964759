.open-shift-view-details-component {
  display: flex;
  padding: 1.6rem;
  width:100vw;

  .data-loading-wrapper {
    //  margin-top:20%;
  }

  .shift-details-wrapper {
    min-width: 30vw;
    max-width: 30vw;
    max-height: 73.5vh;
    overflow: auto;
    padding: 1rem;
    border: 1px solid var(--facility-vars-grays-border-gray, #BEEDF6);
    // background: var(--facility-vars-primary-white, #FFFFFF);
    background: url("../../../../assets/icons/side-menu/open-shift-details.svg") bottom / 100% auto no-repeat, linear-gradient(180deg, #F5FDFF 100%, #FFFFFF 0%);
    border-radius: 3px;
    margin-right: 1rem;

    .shift-details-header {
      color: var(--facility-vars-primary-gunmetal, #202937);
      font-size: 16px;
      font-weight: 600;
    }

    .shift-details-card-wrapper {
      background-color: #FFF;
      padding: 21px 16px;
      border: 1px solid var(--facility-vars-primary-pacific-cyan, #BEEDF6);
      border-radius: 8px;
    }
  }

  .shift-application-wrapper {
    width: 65vw;
    height: 80vh;
    // padding: 1rem;
  }

  .MuiTabs-root {
    width: 30rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 2rem;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .cancel-shift-button {
    box-shadow: none;
    padding: 18px 30px;

  }
  /* Modal Container */
  .conformation-container {
    border: 5px solid red;
    width: 100%;
    height: 100%;
  }
}
