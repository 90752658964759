.add-wing-form-action-button {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wing-timing-table-wrapper {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.wing-add-facility-component {

  .edit-wing-header {
    //background: linear-gradient(180deg, #E0FAFF 23.96%, #FFF 100%);
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}