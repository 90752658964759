.edit-facility-component {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .edit-facility-header {
   // background: linear-gradient(180deg, #E0FAFF 23.96%, #FFF 100%);
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //border-bottom: 1px solid #E4E7EA;
  }

  .form-holder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);

    .add-form-holder {
      flex-grow: 1;
      overflow-y: auto;
      max-height: calc(100vh - 110px); /* Set the max height for the form content */
      flex-shrink: 0;
    }
  }


  .edit-component-content {
    padding-top: 16px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .facility-edit-save-button {
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    background: linear-gradient(180deg, #FFF 23.96%, #E0FAFF 100%);
  }
}
