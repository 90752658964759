.shift-filter-popup{
  position: relative;

  .shift-filter-popup-content{
    position: absolute;
    min-width: 300px;
    top: 100%;
    right: 0;
    margin-top: 10px;
    background-color: #fff;
    //border: 1px solid #ccc;
    box-shadow: 0px 4px 22.4px 0px rgba(0, 0, 0, 0.10);
    display: block;
    z-index: 10;
    border-radius: 8px;

    .shift-creation-popup-wrapper{
      display: flex;
      flex-direction: column;
      gap: .8rem;
      padding:1rem;
      .shift-creation-button{
        padding: 10px;
        border-radius: 8px;

        &:hover{
          background-color: #F1FBFD;
          cursor:pointer;
          font-weight: 600;

        }
      }
    }
  }
}

