@import "src/assets/styles/common";

.forgot-password-screen {
  max-width: 570px;

  .login-form-welcome-text {
    color: $text-light-1;
    font-size: 1.143rem;
  }

  .forget-password-form-helper-text {
    font-size: 2.286rem;
    padding: 15px 0;
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .forget-password-form-info-text {
    font-size: 16px;
    font-weight: 400;
    color: $select-placeholder-color;
    display: flex;
    min-width: 400px;
    // border: 1px solid red;
    // justify-content: center;
    // align-items: center;
    text-align: center;;
  }

  .t-form-controls {
    padding: 20px 0;
  }

  .t-form-actions {
    margin: 0;
  }

  .controls-resend-otp {
    display: flex;
    justify-content: right;
  }

  .control-text {
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 400;
    color: #192938 !important;
  }
}
