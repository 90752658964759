.list-screen {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  border-radius: 12px;
  .list-screen-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px 20px 0 20px;
    flex-wrap: wrap;

    .list-screen-header {
      .list-screen-header-title-and-count {
        display: flex;

        .list-screen-header-title {
          color: #183258;
          font-weight: 600;
          font-size: 18px;
        }
      }

      .list-screen-header-description {
        color: #5F5F61;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 12px;
      }

    }

    .list-options {
      display: flex;
      column-gap: 15px;
    }
  }

  .list-screen-content {
    .t-table {
      height: calc(100vh - 260px);
    }
    .MuiTablePagination-root {
      border-radius: 0 0 8px 8px;
    }
  }
}
