.light-box-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;


  .video-player-component {

  }
}
