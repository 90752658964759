.completed-shift-view-details {

  .MuiTabs-root {
    width: 35rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .approved-header {
    display: flex;
    justify-content: space-between;
    // padding: 10px;
    //background: linear-gradient(162deg, #FFF 23.73%, #E6FFF2 60.63%);
  }

  .shift-details__timesheet {
    display: flex;
    justify-content: right;
  }
}

.document-list-wrapper {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
}


.shift-details-accordion {
  background-color: #f1fbfd !important;
  border-radius: 8px;

  .MuiTypography-root {
    color: #192938;
    font-weight: 600;
    margin: 0;
  }

  .MuiAccordionSummary-root {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #E4E7EA;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
