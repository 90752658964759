@import "src/assets/styles/common";

.file-picker-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: $file-picker-border;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  @include display-center;

  &.drag-active {
    box-shadow: 2px 2px 10px 5px #D9D9D9;
  }

  .file-dnd-icon {
    color: #CFDBDA;
  }

  .file-dnd-title {
    margin: 15px 0 0;
    font-size: 1.1429rem;
    line-height: 24px;

    .file-dnd-title__dnd {
      color: #212B36;
    }

    .file-dnd-title__select {
      color: #212B36;
      text-decoration: underline;
      font-weight: 600;
    }
  }

  .file-dnd-sub-title {
    margin-top: 6px;
    font-size: 16px;
    line-height: 24px;
    color: #212B36;
  }

  .accepted-files-type-text {
    margin-top: 8px;
    color: #9EA2A8;
    font-size: 1.1429rem;
    line-height: 24px;
  }

  .file-dnd-selected-files-details {
    margin: 10px 0;
  }

}
