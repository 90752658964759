@import "src/assets/styles/variables";

.time-picker-component-wrapper {
  //  max-height: 40px;

  .clock-icon {
    position: absolute;
    right: 7px;
    top: 10px;
    z-index: 20;

    &:hover {
      cursor: pointer;
    }
  }

  &.full-width {
    width: 100%;
  }

  .time-picker-input {
    input {
      padding-right: 30px;
    }

    input::placeholder {
      /* Your styles for the placeholder text */
      color:  #C6CACF !important;
      font-size: 14px;
      font-weight: 400 !important; // Placeholder font size
      opacity: 1 !important;/* Placeholder text style */
    }
  }
}

.MuiInputBase-root {
  max-height: 36px;
}

//
//.MuiFormHelperText-root {
//  .Mui-error {
//    color: #3dcc4a !important;
//  }
//}
.MuiFormHelperText-root.Mui-error {
  color: #CE0202 !important;
  font-size: 12px;
  font-family: $font-family sans-serif;
}


