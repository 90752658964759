@import "src/assets/styles/common";

.label-component {
  font-size: 14px;
  color: $label-color;
  font-weight: $label-font-weight;
  margin: 0 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  letter-spacing: -0.05px;

  &.has-error {
    color: $warn-color;
  }

  .tooltip-info {
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    top: 2px;
  }

  .label__optional {
    font-size: 12px;
    color: #B8B8B8;
    font-weight: 500;
  }
}
.bold-header {
  color: var(--Facility-Vars-Primary-Gunmetal, #192938);
  font-size: 14px;
  font-weight: 600;
}