@import "src/assets/styles/variables";

.scheduler-calender-screen {
  margin-left: 20px;
  margin-right: 20px;

  .calender-screen-header {

  }

  .select-dropdown-component {
    margin-bottom: 0;
  }
}

.calender-component-wrapper {
  border: 1px solid #E1E4E7;
  border-radius: 16px;
  //border-top-left-radius: 16px;
  //border-top-right-radius: 16px;
  background-color: #FFF;

  .scheduler-header-text-property {
    display: flex;
    justify-content: center;
    align-items: center;

    .good-morning-text {
      color: var(--Text-Blue, #202937);
      font-family: $font-family, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.1px;
    }

    .shift-info-text {
      color: #9DA4AC;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.1px;
    }
  }
}

.scheduling-filter-header-wrapper {
  display: flex;
  height: auto;
  border-radius: 5px;

  .scheduling-filter-header-date-wrapper {
    flex: 1;
    padding: 10px;
    border-bottom: 1px solid #E7E7E7;
    //margin-bottom: 20px;

    .filter-header-date-text {
      color: var(--text-light-primary, #212B36);
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      //margin-bottom: 4px;
    }

    .filter-header-date-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filter-header-date-control-item {
        color: #212B36FF;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: #212B36FF;
        }
      }

      .filter-shift-type-box{
        width: 300px;
        margin-right: 20px;
      }
    }
  }

  .scheduling-filter-header-actions-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 2;

    .scheduling-filter-header-action-item {
      margin-left: 10px;
      flex: 1;

      .select-component {
        margin-bottom: 0px !important;
      }

      .MuiToggleButton-primary {
        color: #FFFFFF;
        background: #212B36FF;
      }
    }
  }
}

.status-holder-component {
  min-height: 120px;
  //background-color: #0DB9D8;
  .text-resolution {
    font-size: 12px;
    font-weight: 400;
  }

  .open {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #A0E6F8;
    background: #E1F8FF;
    color: #0DB9D8
  }

  .filled {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #49f472;
    background: #c2f6cf;
    color: #1e3c25;
  }

  .unfilled {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #1de3f5;
    background: #e1faf9;
    color: #032b3a;
  }

  .approved {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #FFE3CA;
    background: #FFF4EA;
    color: #E2B749;
  }

  .pending-card {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #a9b6f6;
    background: #deeafa;
    color: #08357a;
  }

  .completed {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #A8ECD3;
    background: #E6FFF2;
    color: #45AE88;
  }

  .confirmed {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #E0E0E0;
    background: #F2F2F2;
    color: #727272;
  }

  .cancelled {
    display: flex;
    width: 105px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: 1px solid #FFCACA;
    background: #FFEFEF;
    color: #E26F6F;
  }
}


.header-for-shift-date-text {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  text-align: center;
  color: var(--text-light-primary, #212B36);
  font-size: 16px;
  font-weight: 700;
}

.shift-details-holder-calender {
  height: 95vh;
  overflow: auto;
}

.icon-for-shift-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 24px;
  border-radius: 50%;
  background-color: black;
  color: white;
}

.plus-icon-button-holder {
  display: flex;
  justify-content: right;
}

.calender-open-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
}

.scheduling-calendar-day-wise-holder {
  display: flex;
  flex-direction: row;

  .scheduling-calendar-day-wise-time-wrapper {
    width: 80px;
    display: flex;
    flex-direction: column;

    .scheduling-calendar-day-wise-time-header {
      background-color: #F5FDFF;
      min-height: 32px;
      max-height: 32px;
    }

    .scheduling-calendar-day-wise-time-body {
      //background-color: #F2F6F6;
      flex: 1;
      padding-top: 7px;

      .scheduling-calendar-time-body-item {
        min-height: 120px;
        max-height: 120px;
        font-weight: 500;
        //margin-top: 16px;
        border-top: 1px solid transparent;
        font-size: 14px;
        color: #212B36;
        text-align: center;
      }
    }
  }

  .scheduling-calendar-right-side-space {
    width: 16px;

    .scheduling-calendar-day-wise-time-header {
      background-color: #F5FDFF;
      min-height: 32px;
      max-height: 32px;
    }
  }

  .scheduling-calendar-day-wise-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    //max-width: calc(100% - 22px);
    overflow: auto;
    border-radius: 0 0 16px 16px;

    .scheduling-calendar-day-wise-item {
      min-width: 147px;
    }

    .scheduling-calendar-day-wise-item {
      &.view-day {
        //min-width: 100%;
        //width: 100%;
      }

      &.view-new-day {
        //min-width: 146px;
        max-width: 146px;
      }

      &.view-3day {
        width: calc(100% / 3);
      }

      &.view-5day {
        width: calc(100% / 5);
      }


      &:first-child {
        .scheduling-calendar-day-wise-item-header {
          //border-left: 1px solid #BEEDF6;
        }
      }

      &:last-child {
        .scheduling-calendar-day-wise-item-header {
          // border-right: 1px solid #BEEDF6;
        }
      }

      display: flex;
      flex: 1;
      flex-direction: column;
      // border-right: 1px solid #E7E7E7;

      .scheduling-calendar-day-wise-item-header {
        min-height: 32px;
        max-height: 32px;
        display: flex;
        background-color: #F9F9F9;
        justify-content: space-between;
        padding-right: 10px;
        padding-left: 10px;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        color: #212B36;
        // border-left: 1px solid #BEEDF6;
        //border-top: 1px solid #BEEDF6;
        border-bottom: 1px solid #E7E7E7;
      }

      &:first-child {
        .scheduling-calendar-hour-block {
          //  border-left: 1px solid #BFC6CD;
        }
      }

      .scheduling-calendar-day-wise-item-body {
        max-height: 65vh;

        .scheduling-calendar-hour-block {
          border-right: 1px solid #E7E7E7;

          .dashed-line {
            border-bottom: 1px dashed #E7E7E7;;
            position: absolute;
            width: 100%;
            top: 50%;
            z-index: 1;
          }

          .scheduling-calendar-hour-block-content:has(.appointment) {
            flex-direction: column;
            column-gap: 1px;
          }

          .scheduling-calendar-hour-block-content {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: row;


            margin: 0 3px;

            .card-item {
              display: flex;
              overflow: hidden;
              flex: 1;
              position: relative;
              box-shadow: 0 0 6px #FAFAFA;

              .hide-dashed-line {
                border-bottom: none;
              }
            }
          }

          &:first-child {
            //   border-top: 1px solid #BFC6CD;
          }
        }
        .scheduling-calendar-hour-block:last-child{
          border:none
        }

      }
    }
  }
}


.requirement-holder-daily-monthly-view {
  border-radius: 8px;
  padding: 10px;
  //border: 1px solid #FFE3CA;
  background: #FFF4EA;
  color: #E2B749;
}

.am-holder {
  //border: 0.5px solid #FF6736;
  background: #FFF5F2;
  color: #FF6736;
  font-size: 14px;
  font-weight: 600;
}

.pm-holder {
  //border: 0.5px solid #10C5D2;
  background: #E9FAFE;
  color: #10C5D2;
  font-size: 14px;
  font-weight: 600;
}

.noc-holder {
  //border: 0.5px solid #108CD2;
  background: #E4F5FF;
  color: #108CD2;
  font-size: 14px;
  font-weight: 600;
}

.am-12-holder {
  //border: 0.5px solid #FF6736;
  background: #FFF5F2;
  color: #FF6736;
  font-size: 14px;
  font-weight: 600;
}

.pm-12-holder {
  //border: 0.5px solid #10C5D2;
  background: #E9FAFE;
  color: #10C5D2;
  font-size: 14px;
  font-weight: 600;
}

.am-pm-double-holder {
  //border: 0.5px solid #FF6736;
  background: #FFF5F2;
  color: #FF6736;
  font-size: 14px;
  font-weight: 600;
}

.pm-noc-double-holder {
  //border: 0.5px solid #10C5D2;
  background: #E9FAFE;
  color: #10C5D2;
  font-size: 14px;
  font-weight: 600;
}

.noc-am-double-holder {
  //border: 0.5px solid #108CD2;
  background: #E4F5FF;
  color: #108CD2;
  font-size: 14px;
  font-weight: 600;
}

.am-13-holder {
  //border: 0.5px solid #FF6736;
  background: #FFF5F2;
  color: #FF6736;
  font-size: 14px;
  font-weight: 600;
}

.pm-13-holder {
  //border: 0.5px solid #10C5D2;
  background: #E9FAFE;
  color: #10C5D2;
  font-size: 14px;
  font-weight: 600;
}

.am-differentiator-holder {
  border-right: 1px solid #FF6736;
  padding-right: 4px;
  margin-right: 4px;
}

.pm-differentiator-holder {
  border-right: 1px solid #10C5D2;
  padding-right: 4px;
  margin-right: 4px;
}

.noc-differentiator-holder {
  border-right: 1px solid #108CD2;
  padding-right: 4px;
  margin-right: 4px;
}

.approved-shift-hcp-details-holder {
  border-radius: 5px;
  //border: 1px solid #ECECEC;
  //background: #FAFAFA;
  padding: 10px;
  margin-bottom: 5px;
}


.scheduling-calendar-week-wise-item-header {
  min-height: 32px;
  max-height: 32px;
  min-width: 170px;
  display: flex;
  background-color: #F5FDFF;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #212B36;
  // border-left: 1px solid #BEEDF6;
  border-top: 1px solid #BEEDF6;
  border-bottom: 1px solid #BEEDF6;
}

.scheduling-calendar-week-wise-item-body {
  //min-height: 70vh;
  //margin-top: 16px;
  //background-color: #F2F6F6;
  //border-left: 1px solid #BFC6CD;
  .scheduling-calendar-hour-block {
    border-right: 1px solid #E7E7E7;;
    border-bottom: 1px solid #BFC6CD;
    //min-height: 290px;
    //max-height: 290px;
    //overflow-y: auto;

    //&:hover {
    //  background-color: #effcff;
    //}

    .dashed-line {
      border-bottom: 1px dashed #E7E7E7;;
      position: absolute;
      width: 100%;
      top: 50%;
      z-index: 1;
    }

    .scheduling-calendar-hour-block-content:has(.appointment) {
      flex-direction: column;
      column-gap: 1px;
    }

    .scheduling-calendar-hour-block-content {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;


      margin: 0 3px;

      .card-item {
        display: flex;
        overflow: hidden;
        flex: 1;
        position: relative;
        box-shadow: 0 0 6px #FAFAFA;

        .hide-dashed-line {
          border-bottom: none;
        }
      }
    }

    &:first-child {
      //   border-top: 1px solid #BFC6CD;
    }
  }
}

.shift-side-bar {
  display: flex;
  height: 130px;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.approved-shift-side-bar {
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  //writing-mode: vertical-lr;
  //transform: rotate(180deg);
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.open-shift-sidebar {
  border-right: 1px solid #E7E7E7;
  background: #F9F9F9;
  min-width: 147px;
}

.pending-shift-sidebar {
  border: 1px solid #E7E7E7;
  background: #7EE3E3;
}

.approved-shift-sidebar {
  border-top: 1px solid #E7E7E7;
  // background: #FFE7D1;
  min-width: 147px;
}

.scheduler-open-shift-timings-card {
  border-radius: 3px;

  display: flex;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin: 1px 5px;
  opacity: 0.8;
}

.scheduler-open-shift-timings-card:hover {
  opacity: 1;
}

.home-health-shift-card {
  background-color: #FFBE90;
}

.contract-travel-shift-card {
  background-color: #C0E5FF;
}

.open-shift-shift-card {
  background: #FDE7A3;
}

.scheduler-approved-shift-timings-card {
  border-radius: 3px;
  display: flex;
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

.week-view-required-hcp-wrapper {
  border-radius: 21px;
  background: #79DFFF;
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: center;
}


//color-code


.scheduler-am-bc-color {
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  background-color: #7FD4B0;
  border-radius: 4px;
}

.scheduler-pm-bc-color {
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  background-color: #C272F3;
  border-radius: 4px;
}

.scheduler-noc-bc-color {
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  background-color: #669FF3;
  border-radius: 4px;
}

.scheduler-purple-type {
  background-color: #C272F3;
  color: #FFFFFF;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.scheduler-green-type {
  background-color: #7FD4B0;
  color: #FFFFFF;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

}

.scheduler-blue-type {
  background-color: #669FF3;
  color: #FFFFFF;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}


.hcp-application-count {
  position: relative;
  border-radius: 100000px;
  background-color: red;
  color: white;
  font-size: 10px;
  display: flex;
  padding: 1px 4px;
  align-items: center;
  left: 100px
}


.open-shift-card {
  position: relative;
  padding-top: 10px;

  .status {
    position: absolute;
    top: 10px;
    right: 42px;
    background-color: #FF4D4D;
    padding: 2px 5px;
    border-radius: 100000px;
    transform: translateY(-50%);
    color: #F9F9F9;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .notifications {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    background-color: #192938;
    padding: 2px 5px;
    border-radius: 100000px;
    transform: translateY(-50%);
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.scheduling-calendar-week-wise-holder {
  display: flex;
  flex-direction: row;

  .scheduling-calendar-week-wise-time-wrapper {
    width: 80px;
    display: flex;
    flex-direction: column;

    .scheduling-calendar-day-wise-time-header {
      background-color: #F5FDFF;
      min-height: 32px;
      max-height: 32px;
    }

    .scheduling-calendar-day-wise-time-body {
      //background-color: #F2F6F6;
      flex: 1;
      padding-top: 7px;

      .scheduling-calendar-time-body-item {
        min-height: 120px;
        max-height: 120px;
        font-weight: 500;
        //margin-top: 16px;
        border-top: 1px solid transparent;
        font-size: 14px;
        color: #212B36;
        text-align: center;
      }
    }
  }

  .scheduling-calendar-right-side-space {
    width: 16px;

    .scheduling-calendar-week-wise-time-header {
      background-color: #F5FDFF;
      min-height: 32px;
      max-height: 32px;
    }
  }

  .scheduling-calendar-week-wise-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    //max-width: calc(100% - 22px);
    overflow: auto;

    .scheduling-calendar-week-wise-item {
      min-width: 147px;
    }

    .scheduling-calendar-week-wise-item {
      &.view-day {
        min-width: 100%;
        width: 100%;
      }

      &.view-3day {
        width: calc(100% / 3);
      }

      &.view-5day {
        width: calc(100% / 5);
      }


      &:first-child {
        .scheduling-calendar-week-wise-item-header {
          //  border-left: 1px solid #BEEDF6;
        }
      }

      &:last-child {
        .scheduling-calendar-week-wise-item-header {
          // border-right: 1px solid #BEEDF6;
        }
      }

      display: flex;
      flex: 1;
      flex-direction: column;

      .scheduling-calendar-week-wise-item-header {
        min-height: 32px;
        max-height: 32px;
        display: flex;
        background-color: #F5FDFF;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        color: #212B36;
        // border-left: 1px solid #BEEDF6;
        border-top: 1px solid #BEEDF6;
        border-bottom: 1px solid #BEEDF6;
      }

      &:first-child {
        .scheduling-calendar-hour-block {
          //  border-left: 1px solid #BFC6CD;
        }
      }

      .scheduling-calendar-week-wise-item-body {
        min-height: 70vh;
        //margin-top: 16px;
        //background-color: #F2F6F6;
        //border-left: 1px solid #BFC6CD;
        .scheduling-calendar-hour-block {
          border-right: 1px solid #E7E7E7;;
          padding: 10px;
          // border-bottom: 1px solid #BFC6CD;
          height: 100%;
          max-height: 70vh;
          overflow-y: auto;
          position: relative;

          //&:hover {
          //  background-color: #effcff;
          //}

          .dashed-line {
            border-bottom: 1px dashed #E7E7E7;;
            position: absolute;
            width: 100%;
            top: 50%;
            z-index: 1;
          }

          .scheduling-calendar-hour-block-content:has(.appointment) {
            flex-direction: column;
            column-gap: 1px;
          }

          .scheduling-calendar-hour-block-content {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: row;


            margin: 0 3px;

            .card-item {
              display: flex;
              overflow: hidden;
              flex: 1;
              position: relative;
              box-shadow: 0 0 6px #FAFAFA;

              .hide-dashed-line {
                border-bottom: none;
              }
            }
          }

          &:first-child {
            //   border-top: 1px solid #BFC6CD;
          }
        }
      }
    }
  }
}


.scheduler-hcp-name-text {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.scheduler-hcp-name-type-text {
  color: #8A8A8A;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}


.hovering-container {
  position: relative;
}

.content {
  height: 100%;
  position: relative;
}

.hover-button {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hovering-container:hover .hover-button {
  opacity: 1;
  z-index: 1;
}

.header-border {
  border-right: 1px solid #E7E7E7;
}

.today-text {
  color: var(--Vars-Colors-Greys-Slate-gray, #797F87);
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 22px;
  margin-left: 5px;
}


.scheduler-cancelled-shift {
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  border-radius: 4px;
  background-color: #FFCDCD;;
}

.scheduler-completed-shift {
  background-color: #CBF3D1;
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  border-radius: 4px;
}

.scheduler-confirmed-shift {
  background-color: #DFDFDF;
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  border-radius: 4px;
}

.scheduler-approved-shift {
  background-color: #FFF3E6;
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  border-radius: 4px;
}

.scheduler-pending-shift {
  background-color: #C0E5FF;
  width: 100%;
  min-width: 126px;
  display: flex;
  margin-right: 4px;
  border-radius: 4px;
}

.calender-add-button {
  padding: 10px;
  color: white;
  background-color: #2d2d2d;
}


.scheduler-tooltip-container {
  position: relative;
  cursor: pointer;
  //display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .scheduler-tooltip-icon{
    margin-top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.scheduler-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  /* Use negative margin to center the tooltip */
  opacity: 0;
  right: 70%;
  top: 40%;
  min-width: 640px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 32.6px 6px rgba(0, 0, 0, 0.07);
  transition: opacity 0.3s;

}

.scheduler-tooltip-container:hover .scheduler-tooltip {
  visibility: visible;
  opacity: 1;
}
