.MuiButton-root {
  text-transform: inherit !important;
}

button {
  white-space: nowrap !important;
}

//.t-table-wrapper {
//  .t-table-column-filter-wrapper {
//    .t-table-column-filter-header {
//      .MuiButton-outlined{
//        background: #FFFFFF !important;
//      }
//    }
//  }
//}
