.add-hcp-type-component{
  width: 27.313rem;
  height:17.625rem;
  border-radius: 40px;
  .dialog-header{
    height: 3.875rem;
    border-bottom: 1px solid #E4E4E4;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    align-items: center;
  }
}