.details-layout {
  background: #EBF1FD;
  height: 100vh;
}

.details-layout-content-holder {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background: url("../../assets/linear-gradient-asset/onboarding-screen.svg") bottom / 100% auto no-repeat, linear-gradient(180deg, #F7F9FC 0%, #E0FAFF 100%);
}