.table-component {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
}

.css-j3l6y9-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected {
  background-color: #1fae6a25 !important;
}
