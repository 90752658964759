@import "src/assets/styles/common";

.textarea-component {
  position: relative;
  margin: 0 0 20px;

  &.fullWidth {
    .textarea-field {
      width: 100%;
    }
  }

  .textarea-field {
    background: #FFF;
    color: #07213CFF;
    border-radius: 8px;
    outline: none;
    padding: 6px 12px;
    font-weight: 400;
    border: 1px solid #E4E7EA;
    font-size: 14px;
    font-family: "Inter", sans-serif;

    &::placeholder {
      color: #C6CACF;
    }

    &:hover {
      //  border: 1px solid colorDarkness($input-border-color, 15);
    }

    &:focus, &:active {
      border: 1px solid #182A39;
    }

    &.has-error {
      border-color: red;
    }

  }

}

.textarea-field::placeholder {
  color: red;
}
