@import "src/assets/styles/common";

.otp-component {
  position: relative;
  margin: 0 0 24px;

  .otp-container {
    input {
      margin-right: 15px;
    }
  }

  &.full-width {
    .otp-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        margin-right: 0;
      }
    }
  }

  &.read-only {
    input {
      pointer-events: none;
    }
  }

  input {
    width: 3.714rem !important;
    height: 3.714rem !important;
    text-align: center;
    color: $input-color;
    background: $default-input-background-color;
    border-radius: $input-border-radius;
    outline: none;
    padding: $input-padding;
    border: $input-border;
    font-size: $input-font-size;
    line-height: $input-line-height;
    font-weight: $input-font-weight;
    //font-family: $input-font-family;
  }

  &.has-error {
    input {
      border-color: $input-error-border-color !important;
    }
  }
}
