.edit-user-component {
  height: 100vh; // Set parent div to full screen height
  display: flex;
  flex-direction: column;

  .edit-user-header {
    height: auto;
    justify-content: space-between;
    padding: 12px 24px;
    .header-title-text{
      color: var(--facility-vars-primary-gunmetal, #192938);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .edit-user-form-container {
    flex: 1; // Form takes the remaining height and is scrollable
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .edit-user-form {
      display: flex;
      flex-direction: column;

      .edit-user-form-content {
        flex: 1; // Form content takes all available space

        .form-fields-wrapper {
          height: calc(100vh - 110px);
          // Add your styles for form fields here
        }

        .form-button {
          display: flex;
          justify-content: center;
          align-items: center// Button sticks to the bottom
          // Add your styles for the button here
        }
      }
    }
  }
}
