.hcp-common-card-details {
  min-width: 550px;
  .hcp-name-text {
    color: var(--Text-Light-Primary, #212B36);
    font-size: 16px;
    font-weight: 700;

  }

  .hcp-type-text {
    color: var(--Vars-Colors-Greys-Slate-gray, #797F87);
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
  }

  .agency-name-text {
    color: var(--Vars-Colors-Greys-Dim-Grey, #5E6368);
    /* Body2 */
    font-size: 14px;
    font-weight: 400;
  }
}