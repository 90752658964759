@import "src/assets/styles/common";

.login-screen {
  padding: 50px 50px;
  min-width: 570px;


  .forget-password-text-container {
    display: flex;
    justify-content: right;
    text-decoration: underline;
  }

  .login-form-container {
    //width: 352px;
    .login-form-welcome-text {
      color: $text-light-1;
      font-size: 1.143rem;
    }

    .login-form-helper-text {
      //font-size: 2rem;
      font-size: 32px;
      padding: 15px 0;
      font-weight: $font-weight-bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .login-form-info-text {
      font-size: 16px;
      font-weight: 500;
      color: $select-placeholder-color;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;;
    }

    .t-form-controls {
      padding: 20px 0;
    }

    .t-form-actions {
      margin: 0;
    }
  }


  .login-text-icon-wrapper {
    display: flex;
  }
}
