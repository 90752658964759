.shift-details-card-calender {
  padding: 14px 10px;
  border-radius: 6px;
  border: 1px solid #E7E7E7;

  background: #FCFCFC;

  .shift-header-calender {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #FAFAFA;
    border-radius: 8px;

    .shift-title-text {
      flex: 1;
    }

    .shift-status-bar {

    }

    .view-icon-holder {

    }
  }
}

.text-resolution {
  font-size: 12px;
  font-weight: 400;
}

.open {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #A0E6F8;
  background: #E1F8FF;
  color: #0DB9D8
}

.approved {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #FFE3CA;
  background: #FFF4EA;
  color: #E2B749;
}

.completed {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #A8ECD3;
  background: #E6FFF2;
  color: #45AE88;
}

.confirmed {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #E0E0E0;
  background: #F2F2F2;
  color: #727272;
}

.cancelled {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #FFCACA;
  background: #FFEFEF;
  color: #E26F6F;
}

.partially_open {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #A0E6F8;
  background: #E1F8FF;
  color: #0DB9D8
}

.filled {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #A0E6F8;
  background: #E1F8FF;
  color: #0DB9D8
}

.unfilled {
  display: flex;
  width: 105px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1px solid #A0E6F8;
  background: #E1F8FF;
  color: #0DB9D8
}