.custom-toggle {
  position: relative;
  width: 32px; /* Updated width */
  height: 20px; /* Updated height */
  background-color: gray;
  border-radius:10px;
  cursor: pointer;

  .switch {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 14px; /* Updated width */
    height: 14px; /* Updated height */
    border-radius: 50%;
    background-color: white;
    transition: left 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }

  &.active {
    background-color: #20AE6E;

    .switch {
      left: 15px; /* Updated left position */
      background-color: white;
    }
  }
}