@import "src/assets/styles/common";

.checkbox-component {
  clear: both;
  position: relative;
  margin-bottom: $font-control-margin-bottom;

  .label-component {
    margin: 0 !important;
    font-size: $checkbox-font-size;
  }

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }

  .checkbox-container .checkbox-field {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $checkbox-border-radius;
    height: $checkbox-size;
    width: $checkbox-size;
    border: $checkbox-border;
    background-color: $checkbox-background-color;
  }

  .checkbox-container .checkbox-field:checked ~ .checkbox-checkmark {
    background-color: $checkbox-base-color;

    &.accent-color {
      background-color: $checkbox-accent-color;
    }
  }

  &.checked {
    .checkbox-container .checkbox-field:checked ~ .checkbox-checkmark {
      &.accent-color {
        background-color: $checkbox-accent-color;
      }
    }
  }

  .checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox-container .checkbox-field:checked ~ .checkbox-checkmark:after {
    display: block;
  }

  .checkbox-container .checkbox-checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    border-radius: 1px;
  }

  &.checked {
    .checkbox-checkmark {
      border-color: $checkbox-base-color;

      &.accent-color {
        border-color: $checkbox-accent-color;
      }
    }
  }

  &.disabled {
    opacity: $checkbox-disabled-opacity !important;

    .checkbox-checkmark {
      background: colorDarkness($input-background-color, 10);
    }

    &:hover {
      cursor: not-allowed !important;
    }
  }

  &.has-error {
    .checkbox-container {
      .label-component {
        //color: $checkbox-error-color !important;
      }
    }

    .checkbox-checkmark {
      //border-color: $checkbox-error-border-color !important;
    }
  }

}

