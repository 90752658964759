@import "src/assets/styles/common";

.menu-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .side-menu-version{
    margin-top: 15px;
    text-align: center;
    font-size: 9px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .ellipses-text {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .horizontal-line-wrapper {
    margin-bottom: 20px;
  }

  .logged-in-user {
    display: flex;
    flex-direction: column;
    //align-items: center;
    margin-bottom: 20px;
    border-radius: 12px;
    border: 1px solid #6EE7FF;
    background: #E0F8FF;
    padding: 10px;
    position: absolute;
    bottom: 8px;
    width: 100%;

    .side-menu-user-wrapper{
      display: flex;
      align-items: center;
      gap: .5rem;
      margin-top: 10px;
      margin-bottom: 15px;

      .side-menu-user-content{
        display: flex;
        flex-direction: column;

        .side-menu-user-title{
          font-size: 14px;
        }
        .side-menu-user-email{
          margin-top: 3px;
          font-size: 10px;
        }
      }
    }
    .side-menu-user-hide{
      display: none;
    }

    &.expanded-view {
      width: 100%;

      .log-out-icon {
        margin-top: 0;
        margin-left: auto;
      }
    }

    .user-avatar {
      border: 1px solid #6EE7FF;
      border-radius: 50%;

      svg {
        display: block;
        margin: 1px;
      }
    }

    .user-name {
      color: #111827;
      font-size: 11.667px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 171.429% */
      margin-left: 4px;
    }

    .log-out-icon {
      cursor: pointer;

      svg {
        display: block;
      }
    }
  }


  .menu-group {
    margin-top: 6px;
    flex: 0;

    &.separator {
      flex: 1;
    }

    .menu-group-title {
      text-transform: capitalize;
      margin-bottom: 6px;
      margin-left: 8px;
      font-weight: 500;
    }

    .menu-group-separator {
      // take rest of the space
      flex: 1 1;
    }

    .menu-group-items {
      .user-wrapper {
        position: absolute;
        bottom: 70px;
      }

      //.action-wrapper {
      //  position: absolute;
      //  bottom: 30px;
      //  width: 87%;
      //  margin-right: 20px;
      //}

      //.user-item {
      //  flex: 1 1;
      //  padding: 7px;
      //  height: 40px;
      //  border-radius: 8px;
      //  font-weight: $side-menu-link-font-weight;
      //  display: flex;
      //  align-items: self-start;
      //  color: #4E535F;
      //  margin-bottom: 2px;
      //
      //  .user-item-icon {
      //    margin-right: 10px;
      //  }
      //}

      .menu-item {
        flex: 1 1;
        height: 40px;
        border-radius: 8px;
        font-weight: $side-menu-link-font-weight;
        display: flex;
        font-size: 14px;
        align-items: center;
        color: #424242;
        margin-bottom: 6px;

        .menu-item-icon {
          // margin-right: 10px;
        }

        .menu-item-label {

        }

        &:hover {
          background-color: #b9f2fd;
          color: #000;
          opacity: 1;
          font-weight: 700;
          cursor: pointer;
          align-items: center; // align the icon and label
          display: flex; // align the icon and label
          .menu-item-icon {
            img {
              color: $side-menu-hover-link-color;
            }
          }
        }

        &.active {
          background-color: $side-menu-active-link-bg-color;
          //color: $side-menu-active-link-color
          color: #000;
          //  font-weight: $side-menu-active-link-font-weight;
          align-items: center; // align the icon and label
          display: flex; // align the icon and label
          font-weight: 700;
          font-size: 14px;

          .menu-item-icon {
            // color: $side-menu-hover-link-color;
            color: #000;
          }
        }
      }

    }
  }
}

.branding-wrapper {
  display: flex;
  //justify-content: center;
  align-items: center;
  margin-bottom: 19px;
  margin-left: 10px;
}

.adding-module-branding-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.vars-health-logo-text {
  font-style: normal;
  margin-left: 5px;
  color: var(--Vars-Colors-Table-Text-Blue, #202937);
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}
