.add-shift-requirement-details-screen {
  .MuiFormControl-root {
    margin: 0 !important;
    padding: 0 !important;
  }

  min-width: 900px;
  max-width: 900px;

  .radio-group-component {
    margin-bottom: 0px;
  }

  .requirement-form-body-wrapper {
    border-radius: 6px;
    border: 1px solid #BEEDF6;
    background: #F5FDFF;
    padding: 20px 16px;
  }

  .body-min-height {
    min-height: 445px;
  }
}

.date-icon {
  position: absolute;
  right: 10px;
  top: 0.6rem;
}


.MuiSvgIcon-fontSizeLarge {
  font-size: 30px;
}

.custom-input {
  cursor: pointer;
  font-size: 16px;
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 2.57rem;
  border: 0.5px solid lightgray;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.custom-input:focus {
  outline: none;
}

.custom-input::placeholder {
  color: #C6C6C6;
  font-size: 14px;
  font-style: normal;
  font-weight: 600; /* Replace with your desired color */
}


.MuiListItem-root.Mui-selected {
  background-color: red !important;
}
