@import "src/assets/styles/common";

.icon-button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    // background: #dddddd;
  }

  &.inherit {
    color: inherit;
    background-color: transparent;
  }

  &.primary {
    color: black;
    background-color: transparent;
  }

  &.secondary {
    color: $button-secondary-base-color;
    background-color: transparent;
  }

  &.error {
    color: $button-error-base-color;
    background-color: transparent;
  }
}
