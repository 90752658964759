@import "src/assets/styles/common";

.radio-group-component {
  margin-bottom: $font-control-margin-bottom;
  position: relative;

  .radio-option-list {
    display: flex;
    flex-wrap: wrap;
  }

  &.row {
    .radio-option-list {
      flex-direction: row;

      .radio-component {
        margin-right: 16px;
      }
    }
  }

  &.column {
    .radio-option-list {
      flex-direction: column;

      .radio-component {
        margin-bottom: 16px;
      }
    }
  }
}


