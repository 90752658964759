.agency-details-component-screen {
  .MuiTabs-root {
    width: 50rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 100px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 20%;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .show-empty-component {
    margin-right: 24rem;
    margin-left: 24rem;
    margin-top: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .show-empty-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .oops-text {
      color: var(--facility-vars-primary-pacific-cyan, #0DB9D8);
      font-size: 24px;
      font-weight: 500;
      margin-top: 20px;
    }

    .info-text {
      margin-top: 20px;
      width: 33rem;
      text-align: center;
      color: #9EA2A8;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }
}