.hcp-details-component-screen {
  .MuiTabs-root {
    width: 30rem;
    max-height: 30px;
    border: 1px solid #E4EAF0;
    border-radius: 6px;
    margin-left: 100px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 50%;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .show-empty-component {
    margin-right: 24rem;
    margin-left: 24rem;
    margin-top: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .show-empty-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .oops-text {
      color: var(--facility-vars-primary-pacific-cyan, #0DB9D8);
      font-size: 24px;
      font-weight: 500;
      margin-top: 20px;
    }

    .info-text {
      margin-top: 20px;
      width: 33rem;
      text-align: center;
      color: #9EA2A8;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }

  .HCPName {
    color: var(--text-light-primary, #212B36);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

  }

  .HCPType {
    color: var(--text-light-primary, #212B36);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .contract-action-text {
    color: var(--text-light-primary, #0DB9D8);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

