.menu-dropdown-component-menu {
  .menu-item {
    display: flex !important;
  }

  .menu-dropdown-option {
    padding: 0 !important;
    //cursor: default !important;
    //background-color: inherit !important;
  }
}
