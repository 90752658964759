.edit-admin-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  .edit-admin-header {
    //padding-top: 16px;
    //padding-right: 24px;
    //padding-left: 24px;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .edit-admin-form-wrapper {
    flex: 1;
    .form-holder {
      height: calc(100%);
      .add-form-holder {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: calc(100%);
      }
    }

    .add-form-action-button {
      position: absolute;
      bottom: 10px;
      left:40%;
    }

    .edit-component-content {
      padding-top: 16px;
      padding-right: 24px;
      padding-left: 24px;
      border-top: 1px solid #E4E7EA;
    }
  }
}