.confirmed-shift-view-details{
  .MuiTabs-root {
    width: 35rem;
    min-height: 34px;
    max-height: 34px;
    border: 1px solid #E4EAF0;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      width: 33.33%;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .approved-header {
    display: flex;
    justify-content: space-between;
    //background: linear-gradient(180deg, #FFF 23.96%, #F2F2F2 100%);
  }
  .shift-details__timesheet{
    display: flex;
    justify-content: right;
  }
}
.document-list-wrapper{
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
}