.shift-rate-component{
  .shift-rate-radio-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .shift-rate-input{
    .input-component{
      margin-bottom: 0 !important;
      width: 120px !important;
    }
  }
  .shift-rate-text{
    font-size: 16px;
    font-weight: 600;
    line-height: 18.8px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
}