.edit-agency-hcp-rate-screen {
  .edit-agency-hcp-rate-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
  }

  .agency-hcp-rate-component {
    .agency-hcp-rate-wrapper {
      padding: 12px 24px;
      height: calc(100vh - 100px);
      overflow: auto;
    }
  }
    .rates-input-wrapper {
      .input-component{
        margin-bottom: 0 !important;
      }
    }

  .center-right-element{
   margin-bottom: 20px;
  }
}
