@import "assets/styles/common";

html, body {
  font-size: 14px;
  font-family: $font-family, sans-serif;
  //@include md() {
  //  font-size: 12px;
  //}
  //
  //@include lg() {
  //  font-size: 14px;
  //}


}

body {
  margin: 0;
  font-family: $font-family, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body-color;
  background: $body-background-color;
  text-transform: capitalize;
}


.system-locked {
  pointer-events: none;
  backdrop-filter: blur(5px) !important;
}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}