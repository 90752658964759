@import "src/assets/styles/common";

.helper-text-component {
  //font-family: $helper-text-font-family;
  font-size: $helper-text-font-size;

  &.error {
    color: $error-color;
  }

  &.success {
    color: $success-color;
  }

  &.warning {
    color: $warning-color;
  }

  &.info {
    color: $info-color;
  }

  &.default {
    color: $body-color;
  }
}
