.drawer-container {
//  padding: 25px !important;
  max-width: 600px;
  min-width: 546px;

  .drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .drawer-title {
      font-size: 1.2857rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .drawer-header-actions {
      display: flex;
      column-gap: 20px;
      align-items: center;

      .drawer-close {
        cursor: pointer;
        position: relative;
        top: 2px;

        svg {
          height: 14px;
          width: 14px;
        }
      }
    }
  }

  .drawer-body {

  }
}
