@import "src/assets/styles/common";
@import "src/assets/styles/variables";

.button-component {
  border-radius: 8px;
  cursor: pointer;
  //box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
  //font-family: $form-control-font-family;
  font-size: $button-font-size;
  font-family: $font-family;
  height: $button-height;
  outline: none;
  padding: $button-padding;
  transition-duration: 0.2s;
  position: relative;
  font-weight: $button-font-weight;
  //margin-bottom: 10px;
  //box-shadow: 0 5px 4px rgba(7, 33, 60, 0.05);
  border: none;
  @include display-center();

  .button-contents {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-prefix, .button-suffix {
    display: flex;
  }

  .button-prefix {
    margin-right: 6px;
  }

  .button-suffix {
    margin-left: 6px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: $button-disabled-opacity;

    &:hover {
      opacity: $button-disabled-opacity !important;
      //background-color: #0fa2bc;
    }
  }


  &.fullWidth {
    width: 100%;
  }

  &.loading {

    .button-contents {
      visibility: hidden;
    }

    &::before {
      content: " ";
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
      border-style: solid;
      width: $button-spinner-width;
      height: $button-spinner-height;
      border-radius: 50%;
      -webkit-animation: loaderSpin 0.5s linear infinite; /* Safari */
      animation: loaderSpin 0.5s linear infinite;
    }
  }

  &.contained {
  }

  &.outlined {
    border: 1px solid;
    background: inherit;
  }

  &.link {
    background: inherit;
    box-shadow: none;
    text-decoration: underline;

    &:hover {
      //box-shadow: 0 5px 4px rgba(7, 33, 60, 0.05);
    }
  }

  &.text {
    background: inherit;
    box-shadow: none;
    text-decoration: none;

    &:hover {
      //box-shadow: 0 5px 4px rgba(7, 33, 60, 0.05);
    }
  }

  &.primary {
    &.loading {
      &::before {
        border-top-color: $side-menu-active-link-bg-color;
      }
    }

    &.link {
      color: $side-menu-active-link-bg-color;
      background-color: #ffffff !important;
    }

    &.contained {
      background-color: $side-menu-active-link-bg-color;
      color: $button-primary-font-color;
      font-weight: 600;

      &:hover {
        background-color: #009BB7;
        opacity: 1;
      }
    }

    &.outlined {
      border-color: $side-menu-active-link-bg-color;
      color: $side-menu-active-link-bg-color;
      background-color: $button-outlined-bg-color;
    }
  }

  &.secondary {

    &.loading {
      &::before {
        border-top-color: $button-secondary-base-color;
      }
    }

    &.link {
      color: $button-secondary-base-color;
      background-color: #ffffff !important;
    }

    &.contained {
      background-color: $button-secondary-base-color;
      color: #ffffff;
    }

    &.outlined {
      border-color: $button-secondary-base-color;
      background-color: #ffffff;
    }
  }

  &.error {
    &.loading {
      &::before {
        border-top-color: $button-error-base-color;
      }
    }

    &.link {
      color: $button-error-base-color;
      background-color: #ffffff !important;
    }

    &.contained {
      background-color: $button-error-base-color;
      color: #ffffff !important;
    }

    &.outlined {
      border-color: $button-error-base-color;
      color: $button-error-base-color;
      background-color: var(--Facility-Vars-Status-Red-Misty-Rose, #FFF4F4);
    }
  }

  &.default {
    &.loading {
      &::before {
        border-top-color: $button-default-base-color;
      }
    }

    &.link {
      color: $button-default-base-color;
      background-color: #ffffff !important;
    }

    &.contained {
      background-color: $button-default-base-color;
      color: #FFFFFF;
    }

    &.outlined {
      border-color: $button-default-base-color;
      background-color: #ffffff;
    }
  }

  &.small {
    padding: $button-sm-padding;
    height: $button-sm-height;
    width: $button-sm-height;
    font-size: $button-sm-font-size;

    .button-text {
      top: -1px;
    }

  }

  &.medium {

  }

  &.download-button {
    &.loading {
      &::before {
        border-top-color: $download-csv-button-background-color;
        opacity: 0.7;
      }
    }

    &.contained {
      background-color: $download-csv-button-background-color;
      color: $download-csv-button-font-color;
      font-weight: 600;

      &:hover {
        background-color: #2A384F;
        opacity: 1;
      }
    }
  }

  &.timesheet-upload-button {
    &.loading {
      &::before {
        border-top-color: $upload-timesheet-button-background-color;
        opacity: 0.7;
      }
    }

    &.contained {
      border: 0.5px solid #D89F0D;
      background-color: $upload-timesheet-button-background-color;
      color: $upload-timesheet-button-font-color;
      font-weight: 600;

      &:hover {
        background-color: $upload-timesheet-button-hover-background-color;
        opacity: 1;
      }
    }
  }


  &.timesheet-upload-button-large {
    &.loading {
      &::before {
        border-top-color: $upload-timesheet-button-background-color;
        opacity: 0.7;
      }
    }

    &.contained {
      min-width: 550px;
      border: 0.5px solid #D89F0D;
      background-color: $upload-timesheet-button-background-color;
      color: $upload-timesheet-button-font-color;
      font-weight: 600;

      &:hover {
        background-color: $upload-timesheet-button-hover-background-color;
        opacity: 1;
      }
    }
  }

  &.deactivate-button {
    &.loading {
      &::before {
        border-top-color:#E01E1E;
        opacity: 0.7;
      }
    }

    &.contained {
      border: 1px solid #E01E1E;
      background-color: #FFF0F0;
      color: #E01E1E;
      font-weight: 600;

      &:hover {
        background-color: #f4d8d8;
        opacity: 1;
      }
    }
  }

  &.exit-onboarding-navigation {
    &.loading {
      &::before {
        border-top-color: #F96868;
        opacity: 0.7;
      }
    }

    &.contained {
      border: 1px solid #FF7575;
      background-color: #FF7575;
      color: #FFFFFF;
      font-weight: 700;

      &:hover {
        background-color: #fb5d5d;
        opacity: 1;
      }
    }
  }
  &.cancel-requirement {
    &.loading {
      &::before {
        border-top-color: #F96868;
        opacity: 0.7;
      }
    }

    &.contained {
      border: 1px solid #E01E1E;
      background-color: #FFF0F0;
      color: #E01E1E;
      font-weight: 700;

      &:hover {
        background-color: #fbe3e3;
        opacity: 1;
      }
    }
  }

  &.empty-button {
    &.loading {
      &::before {
        border-top-color: #F96868;
        opacity: 0.7;
      }
    }

    &.contained {
      //border: 1px solid #E01E1E;
      //  background-color: #FFFFFF;
      background: none !important;
      // color: #E01E1E;
      // font-weight: 700;

      &:hover {
        //  background-color: #fbe3e3;
        //  opacity: 1;
      }
    }
  }

  &.mark-as-completed {
    font-size: 12px !important;

    &.loading {
      &::before {
        border-top-color: #BEEDF6;
        opacity: 0.7;
      }
    }

    &.contained {
      border: 1px solid #BEEDF6;
      background-color: #F5FDFF;
      color: #0DB9D8;
      font-weight: 500;

      &:hover {
        background-color: #def4fa;
        opacity: 1;
      }
    }
  }

  &.remove-as-favorite {
    font-size: 12px !important;

    &.loading {
      &::before {
        border-top-color: #DB5656;
        opacity: 0.7;
      }
    }

    &.contained {
      border: 1px solid #DB5656;
      background-color: #FFF4F4;
      color: #DB5656;
      font-weight: 500;

      &:hover {
        background-color: #fbe8e8;
        opacity: 1;
      }
    }
  }

  &.large-button-size {
    min-width: 100%;
  }
}






