.pdf-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 99999;

  .pdf-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 10px;

    .pdf-header-title {

    }

    .pdf-close-btn {

    }
  }

  .pdf-content-container {
    width: 100vw;
    overflow-y: scroll;
    height: calc(100vh - 120px);
    border-top: 1px solid #8f8f8f;
    border-bottom: 1px solid #8f8f8f;
  }

  .pdf-footer {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 20px;
  }

  .react-pdf__Page__canvas {
    margin: auto !important;
  }
}
