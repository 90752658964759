.document-view-card, .document-upload-card {
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px;

  .document-type {
    flex-shrink: 0;
    width: 220px;
  }

  .document-name {
    flex-shrink: 0;
    width: 140px;

    .ellipses-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
    }
  }

  .document-expiry {
    flex-shrink: 0;
    width: 100px;
  }

  .document-actions {
    display: flex;
    align-items: center;
    width: 98px;

    .upload-button {
      height: 30px;
      color: #202937;
      border: 0.5px solid #CCF1F8;
      background: #F5FDFF;
      box-shadow: none;
    }

    .delete-box {
      margin-right: 5px;
      display: flex;
      padding: 5px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: var(--facility-vars-status-red-misty-rose, #FFF4F4);
      cursor: pointer;
      margin-left: 4px;
    }

    .preview-box {
      margin-right: 5px;
      border: 1px solid #0DB9D8;
      display: flex;
      padding: 7px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: var(--facility-vars-primary-light-cyan, #E1FDFF);
      cursor: pointer;
      margin-left: 4px;
    }
  }
}


.hcp-details-document-wrapper {
  height: 70vh;
  overflow: auto;
  padding: 10px;
}