@import "responsive-mixins";

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important
}
.d-flex-wrap{
  display: flex;
  flex-wrap: wrap;
}
.d-flex-no-wrap{
  display: flex;
  flex-wrap: nowrap;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

@media (min-width: $screen-sm-min) {
  .d-sm-none {
    display: none !important
  }
  .d-sm-inline {
    display: inline !important
  }
  .d-sm-inline-block {
    display: inline-block !important
  }
  .d-sm-block {
    display: block !important
  }
  .d-sm-table {
    display: table !important
  }
  .d-sm-table-row {
    display: table-row !important
  }
  .d-sm-table-cell {
    display: table-cell !important
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width: $screen-md-min) {
  .d-md-none {
    display: none !important
  }
  .d-md-inline {
    display: inline !important
  }
  .d-md-inline-block {
    display: inline-block !important
  }
  .d-md-block {
    display: block !important
  }
  .d-md-table {
    display: table !important
  }
  .d-md-table-row {
    display: table-row !important
  }
  .d-md-table-cell {
    display: table-cell !important
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width: $screen-lg-min) {
  .d-lg-none {
    display: none !important
  }
  .d-lg-inline {
    display: inline !important
  }
  .d-lg-inline-block {
    display: inline-block !important
  }
  .d-lg-block {
    display: block !important
  }
  .d-lg-table {
    display: table !important
  }
  .d-lg-table-row {
    display: table-row !important
  }
  .d-lg-table-cell {
    display: table-cell !important
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width: $screen-xl-min) {
  .d-xl-none {
    display: none !important
  }
  .d-xl-inline {
    display: inline !important
  }
  .d-xl-inline-block {
    display: inline-block !important
  }
  .d-xl-block {
    display: block !important
  }
  .d-xl-table {
    display: table !important
  }
  .d-xl-table-row {
    display: table-row !important
  }
  .d-xl-table-cell {
    display: table-cell !important
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}