@import "../variables";

.modal-container {

  .MuiDialog-paper {
    max-width: unset !important;
    border-radius: 24px !important;
  }

  .modal-content-wrapper {
    padding: 25px !important;
    width: 900px;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .modal-title {
        font-size: 1.1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $primary-color;
      }

      .modal-close {
        cursor: pointer;
        position: relative;
        top: -10px;
        opacity: 0.7;
        left: 10px;

        svg {
          height: 15px;
          width: 15px;
        }
      }
    }

    .modal-body {
    }

    .modal-footer {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }

}
