.card-component {
  background: #ffffff;
  border: 1px solid #DFDFDF;
  border-radius: 12px;
  margin-bottom: 20px;

  &.primary {
    background-color: #EAF3F2;
    margin-bottom: 30px;
    border: 1px solid #E2E2E2 ;
  }


  .card-header {
    padding: 10px 30px;
    background: #EAF3F2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-title-description-wrapper {
      .card-title {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        color: #212B36;
      }

      .card-description {
        margin-top: 8px;
      }
    }

    .card-actions {
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-body {
    padding: 15px 30px;
  }

  &.sm {
    .card-header {
      padding: 10px 20px;

      .card-title {
        font-size: 14px;
        font-weight: 700;
      }
    }

    .card-body {
     padding: 0px 0px;
    }
  }

  &.md {
    .card-header {
      padding: 15px 25px;

      .card-title {
        font-size: 16px;
      }
    }

    .card-body {
      padding: 15px 25px;
    }
  }

  &.xl {
    .card-header {
      padding: 25px 35px;

      .card-title {
        font-size: 20px;
      }
    }

    .card-body {
      padding: 25px 35px;
    }
  }
}
