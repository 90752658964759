.reject-cancelled-component {
  min-width: 530px;
  max-width: 530px;

  .reject-upper-text {
    color: var(--Vars-Health---Facility-Primary-Gunmetal, #212B36);
    font-size: 18px;
    font-weight: 700;
  }

  .reject-lower-text {
    color: var(--vars-health-facility-secondary-cadet-gray-header, #7D7D7D);
    font-size: 16px;
    font-weight: 400;
  }
}


.MuiDialog-paperWidthSm {
  border-radius: 12px !important;
}