$rightSvg: "../../../../assets/linear-gradient-asset/add-layout-right.svg";
$leftSvg: "../../../../assets/linear-gradient-asset/add-layout-left.svg";
$stepperSvg: "../../../../assets/linear-gradient-asset/stepper-component.svg";
.add-agency-details-component {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: url('#{$rightSvg}') no-repeat fixed top right, url('#{$rightSvg}') no-repeat fixed top left;

  .add-agency-sidebar-steper {
    width: 25vw;
  }

  .add-agency-formik-wrapper {
    width: 71vw;
  }
  .add-agency-form-action-element {
    height: 8.7vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-top: 1px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 4px 50.9px 0px rgba(187, 236, 245, 0.50);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .back-button {
      border-radius: 6px;
      border: 1px solid rgba(51, 192, 201, 0.21);
      background: var(--asure-light-cyan, #F1FBFD);
      color: black;
    }
  }
}

.add-agency-sidebar-steper-wrapper {
  border: 1px solid #ECECEC;
  background: url('#{$stepperSvg}') bottom / 100% auto no-repeat, linear-gradient(180deg, #F7F9FC 23.96%, #E0FAFF 100%);
  // background: url('#{$rightSvg}') no-repeat fixed top right, url('#{$rightSvg}') no-repeat fixed top left;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
