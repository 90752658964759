.facility-details-component{
  .add-component-header-form{
    font-size: 16px;
    font-weight: 600;
    .header-text{
      //display: flex;
      //margin-left: 35%;
    }
  }
  .add-component-header-admin{
    font-size: 16px;
    font-weight: 600;
    .header-text{
      //display: flex;
      //margin-left: 35%;
    }
  }

}