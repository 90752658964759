.add-wing-form-action-button {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wing-timing-table-wrapper {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.wing-add-facility-component {

  .edit-wing-header {
    //background: linear-gradient(180deg, #E0FAFF 23.96%, #FFF 100%);
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.edit-add-wing-list-wrapper{
  max-height: 38rem;
  overflow-y: auto;
  padding: 10px;
  .wing-details-holder {
    padding: 10px 22px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    margin-bottom: 12px;

    .facility-add-wing-text {

    }
  }
}